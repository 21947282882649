import { fTimestamp } from "./formatTime";
export default function applyFilter(_a) {
    var inputData = _a.inputData, filterEventColor = _a.filterEventColor, filterStartDate = _a.filterStartDate, filterEndDate = _a.filterEndDate, isError = _a.isError;
    var stabilizedThis = inputData.map(function (el, index) { return [el, index]; });
    inputData = stabilizedThis.map(function (el) { return el[0]; });
    if (filterEventColor && filterEventColor.length) {
        inputData = inputData.filter(function (event) {
            return filterEventColor.includes(event.color);
        });
    }
    if (filterStartDate && filterEndDate && !isError) {
        inputData = inputData.filter(function (event) {
            return fTimestamp(event.start) >= fTimestamp(filterStartDate) &&
                fTimestamp(event.end) <= fTimestamp(filterEndDate);
        });
    }
    return inputData;
}
